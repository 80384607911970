.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


article {
  max-width: 700px;
  margin: auto;
}

a {
  cursor: pointer;
  color: #EB005B;
}

a.sourceLine {
  cursor: auto;
}

.sourceCode {
  margin: 1em 0;
}

.email-form {
  max-width: 500px;
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  width: 100%;
}

.navbar>* {
  margin-right: 1em;
}

.navbar-title.title {
  margin-bottom: 0;
}

.hover-card {
  position: relative;
  background: rgba(255, 255, 255, 0.95);
  padding: 1em;
  border: 1px solid gray;
  border-radius: 7px;
  z-index: 100;
  box-shadow: 2px 2px 10px 1px;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
